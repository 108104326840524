<template>
  <div class="three-scene" ref="three-scene" onselectstart="return false;">
    <div @pointerdown="(e) => e.stopPropagation()" class="btn">
      <button
        @click="snapshot(i)"
        v-for="(i, _) in ['交换机', '监控', '机柜', '初始']"
      >
        {{ i }}
      </button>
    </div>
    <div class="machineInfo" @pointerdown="(e) => e.stopPropagation()">
      <!-- 设备概况 -->
      <div class="machine-summarize">
        <div class="title">
          <div class="close" @pointerdown="close"></div>
        </div>
        <div class="content"></div>
      </div>
      <!-- 端口信息 -->
      <div class="portInfo"></div>
      <!-- 交换机信息 -->
      <div class="switchInformation"></div>

      <!-- 生成连接 -->
      <div class="generate-connection">
        <div v-for="(objValue, key, index) of generateInfo" :key="index">
          <el-input
            placeholder="请输入链接"
            v-model="objValue.input"
            class="connection-input"
            v-show="!objValue.isShow"
          >
          </el-input>
          <div
            @pointerdown="clickGenerate(objValue, key)"
            class="generate"
            v-show="!objValue.isShow"
          >
            <span> 生成</span>
          </div>
          <div
            v-show="objValue.isShow"
            class="target"
            @pointerdown="jump(objValue, $event)"
          >
            {{ objValue.text }}
          </div>
        </div>
      </div>
    </div>

    <div class="left"></div>

    <div class="right"></div>

    <div class="top"></div>

    <div class="bottom"></div>
  </div>
</template>

<script>
// chang 事件 实例
let sceneChange = null;
// 场景
let scene = null;
import Engine from "run-scene-v2";
import store from "../../store";
import * as THREE from "three";
import { fn } from "./Change";
import bus from "./../../lib/bus";
import router from "../../router";
const { RunScene, Utils } = Engine;
export default {
  name: "Threescene",
  data() {
    return {
      path:
        // "http://192.168.3.8:8080/file?path=project/linkpoint/&key=202307041531284082051001202386",
        "https://3d-editor-2-1303915342.cos.ap-shanghai.myqcloud.com/202307041531284082051001202386.glb",
      // 生成连接功能
      generateInfo: {
        port: {
          input: "",
          url: "",
          text: "交换机端口-信息查看",
          isShow: false,
        },
        web: {
          input: "",
          url: "",
          text: "交换机管理-WEB模式",
          isShow: false,
        },
        ping: {
          input: "",
          url: "",
          text: "交换机管理-命令行模式",
          isShow: false,
        },
      },
      // 是否显示看板
      isShowGenerateInfo: false,
    };
  },
  async mounted() {
    const runScene = await this.loadScene(this.path);
    // 加载场景

    // 打印点击的模型接口
    bus.$on("logClickModel", this.logClickModel);

    // 场景加载完回调
    runScene.on("loaded", () => {
      this.resize(store.state.fitLayoutRatio, scene);

      this.$refs["three-scene"].classList.add("show");

      this.$emit("load");

      fn(
        scene,
        {
          Utils,
          bus,
          Three: THREE,
          store,
        },
        {}
      );

      this.onDone();
    });

    // 场景运行状态
    bus.$on("sceneRunning1", (isRuning) => {
      this.isRuning = isRuning;
    });

    bus.$on("showMachineinfo", (isShow) => (this.isShowGenerateInfo = isShow));

    // bus.$emit("blueprintFn", "运行产线");
  },
  methods: {
    close() {
      bus.$emit("showMachineinfo", false);
    },
    jump(objValue, e) {
      try {
        window.open(objValue.url);
        // e.preventDefault();
        // return false;
      } catch (error) {
        console.log("error:", error);
      }
    },
    clickGenerate(objValue, key) {
      objValue.isShow = true;
      objValue.url = objValue.input;
      console.log("objValue,key:", objValue, key);
    },
    // 加载场景
    loadScene(path) {
      scene = new RunScene({
        render2: true,
        render3: true,
        renderConfig: {
          // 是否允许设置模型位置后自动渲染最新效果
          matrixAutoUpdate: true,
          scriptFrame: 60,
          event: {
            ignores: ["resize"],
          },
        },
      }).load({
        path: path,
        dom: this.$refs["three-scene"],
      });

      scene.setSize(3040, 1040);

      return scene;
    },

    openDialog(item, event, type) {
      this.$store.state.innerType1 = type.split("_")[0];
      scene?.cb.controls.change.add("setDialogPosition", () => {
        if (!this.isRuning) return;
        bus.$emit("set-position-dialogThree", item, event);
      });

      // 设置 唯一dom位置
      bus.$emit("set-position-dialogThree", item, event);

      bus.$emit("set-state-dialogThree", true);
    },

    // 自适应
    resize: (x, runScene) => {
      let map = runScene.assetsEx.engineDom.getBoundingClientRect();
      runScene.setSize(map.width / x, map.height / x);
    },

    // 加载完回调
    onDone() {
      // console.log("场景加载完毕~");
    },

    // 快照
    snapshot(name) {
      bus.$emit("snapShotFn", name);
    },
    // 打印点击到的模型
    logClickModel(model) {
      console.log("点击的模型为:", model.name);
    },
  },
  // 场景自带销毁
  destroyed() {
    sceneChange && sceneChange.dispose();
  },
};
</script>

<style lang="scss" scoped>
// 场景
.three-scene {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: black;
  pointer-events: auto;
}

.left {
  background-image: url("./../../../public/assets/images/左侧.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  left: 24px;
  top: 110px;
  z-index: 3;
  width: 360px;
  height: 905px;
}

.right {
  background-image: url("./../../../public/assets/images/右侧.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  right: 24px;
  top: 110px;
  z-index: 3;
  width: 360px;
  height: 905px;
}

.top {
  width: 100%;
  height: 171px;
  background-image: url("./../../../public/assets/images/顶部.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  top: 0;
  position: absolute;
  z-index: 3;
}

.bottom {
  width: 100%;
  height: 25px;
  background-image: url("./../../../public/assets/images/底栏2.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  bottom: 0;
  position: absolute;
  z-index: 3;
}

.machineInfo {
  padding: 10px;
  padding-left: 15px;
  width: 280px;
  height: 548px;
  position: absolute;
  z-index: 5;
  background-image: url("./../../../public/assets/images/弹窗.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: none;
  // bottom: 0;
  .machine-summarize {
    height: auto;
    width: 248px;
    position: relative;
    .title {
      width: 100%;
      height: 34px;
      margin-top: 8px;
      background-image: url("./../../../public/assets/images/标题.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;

      .close {
        position: absolute;
        width: 10px;
        height: 10px;
        background-image: url("./../../../public/assets/images/关闭.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        right: 12.64px;
        top: 6.36px;
        cursor: pointer;
      }
    }
    .content {
      width: 100%;
      height: 72px;
      margin-top: 12px;
      background-image: url("./../../../public/assets/images/信息.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }

  .portInfo {
    width: 254px;
    height: 149px;
    background-image: url("./../../../public/assets/images/端口信息.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .switchInformation {
    width: 254px;
    height: 149px;
    background-image: url("./../../../public/assets/images/交换机信息.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .generate-connection {
    width: 246px;
    height: 96px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    > div {
      width: 100%;
      max-height: 28px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 6px;

      // 输入框
      :deep(.connection-input) {
        max-width: 150px;
        max-height: 28px;

        > input {
          border: none;
          max-height: 28px;
          background-color: rgba(0, 0, 0, 0.2);
        }
        .connection-input {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      // 生成
      .generate {
        width: 50px;
        height: 100%;
        color: rgba(255, 255, 255, 0.8);
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.2);
        display: flex;
        font-size: 12px;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        > span {
          transform: scale(0.9);
        }
      }

      // 跳转按钮
      .target {
        width: 100%;
        height: 100%;
        color: rgba(255, 255, 255, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        gap: 10px;
        border: 1px solid rgba(255, 255, 255, 0.4);
        background: rgba(255, 255, 255, 0.1);
      }
    }
  }

  .generate-connection > div:first-child {
    margin-top: 0px;
  }
}

.three-scene .btn {
  position: absolute;
  z-index: 20;
}

.three-scene .show {
  opacity: 1 !important;
}

.three-scene .block {
  display: block !important;
}

.three-scene .none {
  display: none;
}

.showOpacity {
  opacity: 1 !important;
}
</style>
